import ButtonAppBar from "./AppBar";
import React from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Paper,
} from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Twoday from "./images/twoday.png";
import ATP from "./images/atp.png";
import Green from "./images/green.png";
import Youandx from "./images/youandx.png";
import Swush from "./images/swush.png";
import RBU from "./images/red_barnet_ungdom.png";
import Øjenlægerne from "./images/ojenlaegerne.png";
import Joe from "./images/joe.png";

// Create responsive themes
let theme = createTheme({
  typography: {},
  palette: {
    primary: {
      main: "#4caf50", // Change to desired color
    },
  },
});
theme = responsiveFontSizes(theme);

// Job experiences data
const jobData = [
  {
    year: "2024-",
    title: "Data Scientist at twoday",
    description:
      "Developing AI-applications for customers using technologies and services such as Azure, OpenAI, PyTorch, MLflow and much more!",
    logo: Twoday,
  },
  {
    year: "2023-2024",
    title: "Data Scientist at ATP",
    description:
      "Developing Python-based statistical models using machine learning, improving ML operations, creating Flask solutions for product visualization, influencing the tech stack, setting risk-mitigating business rules, and leading data science projects from concept to production.",
    logo: ATP,
  },
  {
    year: "2022-2023",
    title: "Data Engineer at GreenMobility",
    description:
      "Design and implementation of ELT pipelines to support data-driven applications, monitoring and optimizing data pipeline performance, managing data quality and data cleaning, and close collaboration with stakeholders and developers in the development and design of data models, dashboards, and algorithms.",
    logo: Green,
  },
  {
    year: "2022",
    title: "Full-stack Developer at Youandx",
    description:
      "Development and maintenance of the website, booking system, payment system, etc.",
    logo: Youandx,
  },
  {
    year: "2021-2022",
    title: "Software Developer at Swush",
    description:
      "Developing various software for managing and administering their site and games.",
    logo: Swush,
  },
  {
    year: "2020-2022",
    title: "Junior IT-consultant at Red Barnet Ungdom",
    description:
      "Maintaining and developing their database and webpage, as well as assisting with IT-support.",
    logo: RBU,
  },
  {
    year: "2015-2021",
    title: "Assistant at Øjenlægerne Glostrup Centrum",
    description:
      "Measuring patient vision, ocular pressure, refraction, retina photography, visual field measurement (perimetry) and optical coherence tomography (OCT).",
    logo: Øjenlægerne,
  },
  {
    year: "2014-2015",
    title: "Juicer at Joe and the Juice",
    description: "Making juice, coffee and sandwiches",
    logo: Joe,
  },
];

// CV component
export default function CV() {
  return (
    <>
      <ButtonAppBar />
      <Grid container spacing={1} padding={0}>
        <Grid item xs={0} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            style={{
              padding: "2%",
              background: "#FFFFFF",
              borderRadius: "15px",
              margin: "2%",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                variant="h4"
                color="#000000"
                padding="2%"
                style={{
                  fontFamily: "'Urbanist', sans-serif",
                  fontWeight: 500,
                }}
              >
                Work Experience
              </Typography>
              {jobData.map((job, index) => (
                <Accordion
                  key={index}
                  style={{
                    margin: "1% 0",
                    borderRadius: "10px",
                    background: "#F8F9F9",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index + 1}-content`}
                    id={`panel${index + 1}-header`}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <Avatar
                          alt={job.title}
                          src={job.logo}
                          style={{ marginRight: "16px", borderRadius: "50%" }}
                        />
                        <Typography
                          style={{
                            fontFamily: "'Urbanist', sans-serif",
                            fontWeight: 100,
                          }}
                        >
                          {job.title}
                        </Typography>
                      </div>
                      <div style={{ marginRight: "24px" }}>
                        <Typography
                          sx={{ color: "text.secondary" }}
                          style={{
                            fontFamily: "'Urbanist', sans-serif",
                            fontWeight: 100,
                          }}
                        >
                          {job.year}
                        </Typography>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ borderRadius: "0 0 10px 10px" }}>
                    <Typography
                      style={{
                        fontFamily: "'Urbanist', sans-serif",
                        fontWeight: 100,
                      }}
                    >
                      {job.description}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </ThemeProvider>
          </Paper>
        </Grid>
        <Grid item xs={0} md={3}></Grid>
      </Grid>
    </>
  );
}

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { Grid } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

let buttonTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
});

buttonTheme = responsiveFontSizes(buttonTheme);

export default function ButtonAppBar() {
  const navigate = useNavigate();
  const handleClick = (url) => navigate(url);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  function LinkButton({ text, url }) {
    return (
      <Button
        style={{
          backgroundColor: "transparent",
          fontFamily: "'Urbanist', sans-serif",
          fontWeight: 100,
          color: "#000000",
        }}
        onClick={() => handleClick(url)}
      >
        {text}
      </Button>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{
          background: "#FFFFFF",
        }}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} md={3}>
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h4"
                  align="center"
                  style={{
                    paddingTop: isSmallScreen ? "20px" : "0",
                    fontFamily: "'Urbanist', sans-serif",
                    fontWeight: 900,
                    color: "#000000",
                  }}
                >
                  Magnus Diamant
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 1,
                  m: 1,
                  borderRadius: 1,
                }}
              >
                <ThemeProvider theme={buttonTheme}>
                  <LinkButton text="Home" url="/about" />
                </ThemeProvider>
                <ThemeProvider theme={buttonTheme}>
                  <LinkButton text="CV" url="/cv" />
                </ThemeProvider>
                <ThemeProvider theme={buttonTheme}>
                  <LinkButton text="Education" url="/education" />
                </ThemeProvider>
                <ThemeProvider theme={buttonTheme}>
                  <LinkButton text="Contact" url="/contact" />
                </ThemeProvider>
              </Box>
            </Grid>
            <Grid item xs={0} md={3}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
